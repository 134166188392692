import React from "react";
import { PortableText } from "@portabletext/react";
import styled from "styled-components";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  ${(props) => props?.margin?.top && `margin-top: ${props?.margin?.top}px`};
  ${(props) => props?.margin?.top && `margin-right: ${props?.margin?.right}px`};
  ${(props) =>
    props?.margin?.top && `margin-bottom: ${props?.margin?.bottom}px`};
  ${(props) => props?.margin?.top && `margin-left: ${props?.margin?.left}px`};
  ${(props) => props?.margin?.top && `padding-top: ${props?.padding?.top}px`};
  ${(props) =>
    props?.padding?.top && `padding-right: ${props?.padding?.right}px`};
  ${(props) =>
    props?.padding?.top && `padding-bottom: ${props?.padding?.bottom}px`};
  ${(props) =>
    props?.padding?.top && `padding-left: ${props?.padding?.left}px`};
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Attribution = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 24px;
  color: #676a6b;
  text-align: center;
  p {
    margin: 0;
  }
`;

export default function Figure({ width, height, value, src, url }) {
  const cWidth = value?.width === 0 ? "100%" : `${value?.width}%`;
  return (
    <Wrapper margin={value?.margin} padding={value?.padding}>
      <ImageContainer>
        <div style={{ width: cWidth }}>
          <Image
            src={src}
            alt={value?.alt ? value?.alt : url}
            width={width}
            height={height}
            style={{
              marginBottom:
                value.attribution && value.attribution.length > 0 ? 0 : 15,
              marginTop: 15,
            }}
          />
        </div>
      </ImageContainer>
      {value.attribution && value.attribution.length > 0 && (
        <Attribution>
          <PortableText value={value.attribution} />
        </Attribution>
      )}
    </Wrapper>
  );
}
