import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
`;
export default function Spacer({ value }) {
  return <Wrapper height={value?.height} />;
}
