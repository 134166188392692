import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

const Container = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: ${(props) => props?.placement};
`;

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Attribution = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 24px;
  color: #676a6b;
  text-align: center;
  p {
    margin: 0;
  }
`;

export default function EmbedVideo({ value }) {
  const width = value?.width === 0 ? "100%" : `${value?.width}%`;
  return (
    <Container placement={value?.placement}>
      <div style={{ width: width }}>
        <Wrapper dangerouslySetInnerHTML={{ __html: value?.code }} />
        {value.attribution && value.attribution.length > 0 && (
          <Attribution>
            <PortableText value={value.attribution} />
          </Attribution>
        )}
      </div>
    </Container>
  );
}
