import React from "react";
import { graphql } from "gatsby";
import PageBuilder from "../components/pageBuilder";
import { SEO } from "../components/SEO";

const LandingPage = ({ data }) => {
  const landingPage = data.sanityLandingPageBuilder;

  return (
    <div>
      <PageBuilder content={landingPage} />
    </div>
  );
};

export default LandingPage;

export const Head = () => <SEO />;

export const query = graphql`
  query ($id: String!) {
    sanityLandingPageBuilder(id: { eq: $id }) {
      id
      title
      description
      _rawBuilder
    }
  }
`;
