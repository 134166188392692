import React from "react";
import { PortableText } from "@portabletext/react";
import { getImageDimensions } from "@sanity/asset-utils";
import { getSanityImageUrl } from "../../../../utils/sanity";
import Typography from "../typography";
import Paragraph from "../paragraph";
import Figure from "../figure";
import Spacer from "../spacer";
import Divider from "../divider";
import EmbedCode from "../embedCode";
import EmbedVideo from "../embedVideo";
import Gallery from "../gallery";
import Table from "../table";

export default function PortableContent({ pageContent }) {
  const url =
    typeof window !== "undefined"
      ? window.location.pathname.replace("/", "")
      : "";
  return (
    <PortableText
      value={pageContent}
      components={{
        marks: {
          color: (item) => (
            <span style={{ color: item?.value?.hex }}>{item?.children}</span>
          ),
        },
        types: {
          heading: (item) => <Typography component={item?.value} />,
          paragraph: (item) => <Paragraph content={item?.value} />,
          figure: ({ value }) => {
            const { width, height } = getImageDimensions(value?.image);
            return (
              <Figure
                value={value}
                width={width}
                height={height}
                src={getSanityImageUrl(value?.image?.asset?._ref)}
                url={url}
              />
            );
          },
          spacer: ({ value }) => <Spacer value={value} />,
          divider: ({ value }) => <Divider value={value} />,
          pEmbedCode: ({ value }) => <EmbedCode value={value} />,
          embedVideo: ({ value }) => <EmbedVideo value={value} />,
          gallery: ({ value }) => <Gallery value={value} url={url} />,
          pageTable: ({ value }) => <Table value={value} />,
        },
      }}
    />
  );
}
