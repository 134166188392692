import React, { Fragment } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.placement};
`;

const Tag = styled.h1`
  color: ${(props) =>
    props.color
      ? `rgba(${props?.color?.r},${props?.color?.g}, ${props?.color?.b}, ${props?.color?.a})`
      : "black"};
  width: 100%;
  ${(props) => props?.width && `max-width: ${props.width}px`};
  ${(props) => props?.decoration && `text-decoration: ${props.decoration}`};
  text-align: ${(props) => props.align};
  ${(props) => props?.italic && `font-style: ${props.italic}`};
  font-weight: ${(props) => (props?.fontWeight ? props.fontWeight : "bold")};
  ${(props) => props?.fontSize && `font-size: ${props?.fontSize}px !important`};
  ${(props) =>
    props?.letterSpacing && `letter-spacing: ${props?.letterSpacing}px`};
  ${(props) => props?.margin?.top && `margin-top: ${props?.margin?.top}px !important`};
  ${(props) => props?.margin?.right && `margin-right: ${props?.margin?.right}px !important`};
  ${(props) =>
    props?.margin?.bottom && `margin-bottom: ${props?.margin?.bottom}px !important`};
  ${(props) => props?.margin?.left && `margin-left: ${props?.margin?.left}px !important`};
  ${(props) => props?.padding?.top && `padding-top: ${props?.padding?.top}px !important`};
  ${(props) =>
    props?.padding?.right && `padding-right: ${props?.padding?.right}px !important`};
  ${(props) =>
    props?.padding?.bottom && `padding-bottom: ${props?.padding?.bottom}px !important`};
  ${(props) =>
    props?.padding?.left && `padding-left: ${props?.padding?.left}px !important`};
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

export default function Typography({ component }) {
  return (
    <Wrapper placement={component?.placement}>
      {component?.strike ? (
        <Tag
          as={component?.variant}
          color={component?.color?.rgb}
          width={component?.width}
          decoration={component?.underline}
          align={component?.alignment}
          italic={component?.italic}
          fontWeight={component?.fontWeight}
          margin={component?.margin}
          padding={component?.padding}
          letterSpacing={component?.letterSpacing}
          fontSize={component?.fontSize}
        >
          <s>
            {component?.link ? (
              <Link href={component?.link}>{component?.label}</Link>
            ) : (
              <Fragment>{component?.label}</Fragment>
            )}
          </s>
        </Tag>
      ) : (
        <Tag
          as={component?.variant}
          color={component?.color?.rgb}
          width={component?.width}
          align={component?.alignment}
          italic={component?.italic}
          fontWeight={component?.fontWeight}
          margin={component?.margin}
          padding={component?.padding}
          letterSpacing={component?.letterSpacing}
          fontSize={component?.fontSize}
        >
          {component?.link ? (
            <Link href={component?.link}>{component?.label}</Link>
          ) : (
            <Fragment>{component?.label}</Fragment>
          )}
        </Tag>
      )}
    </Wrapper>
  );
}
