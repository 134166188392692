import React from "react";
import styled from "styled-components";
import { getImageDimensions } from "@sanity/asset-utils";
import { getSanityImageUrl } from "../../../../utils/sanity";

const Wrapper = styled.div`
  ${(props) => props?.margin?.top && `margin-top: ${props?.margin?.top}px`};
  ${(props) => props?.margin?.top && `margin-right: ${props?.margin?.right}px`};
  ${(props) =>
    props?.margin?.top && `margin-bottom: ${props?.margin?.bottom}px`};
  ${(props) => props?.margin?.top && `margin-left: ${props?.margin?.left}px`};
  ${(props) => props?.margin?.top && `padding-top: ${props?.padding?.top}px`};
  ${(props) =>
    props?.padding?.top && `padding-right: ${props?.padding?.right}px`};
  ${(props) =>
    props?.padding?.top && `padding-bottom: ${props?.padding?.bottom}px`};
  ${(props) =>
    props?.padding?.top && `padding-left: ${props?.padding?.left}px`};
`;

const Row = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 479px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Col = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height: 20vw;
  @media (max-width: 767px) {
    height: 40vw;
  }
  @media (max-width: 479px) {
    height: 60vw;
  }
`;

export default function Gallery({ value, url }) {
  return (
    <Wrapper margin={value?.margin} padding={value?.padding}>
      <Row>
        {value?.images?.map((item) => {
          const { width, height } = getImageDimensions(item);
          return (
            <Col>
              <Image
                src={getSanityImageUrl(item?.asset?._ref)}
                alt={item?.alt ? item?.alt : url}
                width={width}
                height={height}
              />
            </Col>
          );
        })}
      </Row>
    </Wrapper>
  );
}
