import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: ${(props) => props?.placement};
`;
const Wrapper = styled.div`
  border: 2px solid ${(props) => props?.color};
  width: ${(props) => props?.width};
`;
export default function Divider({ value }) {
  const color = value?.color
    ? `rgba(${value?.color?.rgb?.r},${value?.color?.rgb?.g},${value?.color?.rgb?.b},${value?.color?.rgb?.a})`
    : "black";
  const width = value?.width === 0 ? "100%" : `${value?.width}px`;
  return (
    <Container placement={value?.placement}>
      <Wrapper height={value.height} width={width} color={color} />
    </Container>
  );
}
