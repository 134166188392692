import React from "react";
import { PortableText } from "@portabletext/react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.placement};
  ${(props) =>
    props?.margin?.top && `margin-top: ${props?.margin?.top}px !important`};
  ${(props) =>
    props?.margin?.right &&
    `margin-right: ${props?.margin?.right}px !important`};
  ${(props) =>
    props?.margin?.bottom &&
    `margin-bottom: ${props?.margin?.bottom}px !important`};
  ${(props) =>
    props?.margin?.left && `margin-left: ${props?.margin?.left}px !important`};
  ${(props) =>
    props?.padding?.top && `padding-top: ${props?.padding?.top}px !important`};
  ${(props) =>
    props?.padding?.right &&
    `padding-right: ${props?.padding?.right}px !important`};
  ${(props) =>
    props?.padding?.bottom &&
    `padding-bottom: ${props?.padding?.bottom}px !important`};
  ${(props) =>
    props?.padding?.left &&
    `padding-left: ${props?.padding?.left}px !important`};
  p {
    width: 100%;
    ${(props) => props?.width && `max-width: ${props.width}px`};
    text-align: ${(props) => props.align};
    ${(props) => props?.fontSize && `font-size: ${props?.fontSize}px`};
    ${(props) =>
      props?.letterSpacing && `letter-spacing: ${props?.letterSpacing}px`};
    margin-bottom: 10px;
  }
  a {
    text-decoration: underline;
    color: black;
  }
  ul {
    width: 100%;
    ${(props) => props?.width && `max-width: ${props.width}px`};
  }
`;

export default function Paragraph({ content }) {
  return (
    <Wrapper
      margin={content?.margin}
      padding={content?.padding}
      letterSpacing={content?.letterSpacing}
      fontSize={content?.fontSize}
      align={content?.alignment}
      width={content.width}
      placement={content?.placement}
    >
      <PortableText
        value={content?.content}
        components={{
          marks: {
            color: (item) => (
              <span
                style={{
                  color: `rgba(${item?.value?.rgb?.r},${item?.value?.rgb?.g},${item?.value?.rgb?.b},${item?.value?.rgb?.a})`,
                }}
              >
                {item?.children}
              </span>
            ),
            highlight: (item) => (
              <span
                style={{
                  backgroundColor: "yellow",
                }}
              >
                {item?.children}
              </span>
            ),
            sup: (item) => <sup>{item?.children}</sup>,
            sub: (item) => <sup>{item?.children}</sup>,
          },
        }}
      />
    </Wrapper>
  );
}
