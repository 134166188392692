import React from "react";
import styled from "styled-components";
import PortableContent from "./components/portableContent";

const Builder = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    line-height: 140%;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 25px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
`;
export default function PageBuilder({ content }) {
  return (
    <Builder>
      <PortableContent pageContent={content?._rawBuilder} />
    </Builder>
  );
}
