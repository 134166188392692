import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

const Container = styled.div`
  padding-top: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Attribution = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 24px;
  color: #676a6b;
  text-align: center;
  p {
    margin: 0;
  }
`;
export default function EmbedCode({ value }) {
  return (
    <Container>
      <Wrapper dangerouslySetInnerHTML={{ __html: value?.code }}></Wrapper>
      {value.attribution && value.attribution.length > 0 && (
        <Attribution>
          <PortableText value={value.attribution} />
        </Attribution>
      )}
    </Container>
  );
}
